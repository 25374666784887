import React, { useState } from "react";
import { Container } from "react-bootstrap";

import "../pages.css";

const MyResume = () => {
  const [password, setPassword] = useState("");
  const [authenticated, setAuthenticated] = useState(false);

  const passwordValue = process.env.REACT_APP_PASSWORD;

  const handleSubmit = (event) => {
    event.preventDefault();
    if (password === passwordValue) {
      setAuthenticated(true);
    } else {
      alert("Incorrect password!");
    }
  };

  const handleRequestPassword = () => {
    window.location.href = `mailto:info@sonjacodes.com?subject=Password%20Request&body=Please%20send%20me%20the%20password%20to%20access%20your%20CV.`;
  };

  return (
    <Container className="MyResume">
      <div className="MyResume">
        {authenticated ? (
          <>
            <h1>My Resume</h1>
            <p>You may view, print or download my resume below.</p>
            <div className="download-btn">
              <a
                href={`${process.env.PUBLIC_URL}/CV - Sonja Kolmer.pdf`}
                className="btn btn-primary mb-3"
                title="CV"
                target="_blank"
                rel="noopener noreferrer"
                view="CV - Sonja Kolmer.pdf">
                Follow me to view CV
              </a>
            </div>
          </>
        ) : (
          <div>
            <h3>Enter Password</h3>
            <form onSubmit={handleSubmit}>
              <label for="password">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
              <button type="submit" className="submit-btn">
                Submit
              </button>
            </form>
            <p className="no-password">
              If you haven't received a password, you can request it{" "}
              <button
                onClick={handleRequestPassword}
                className="no-password-btn">
                here
              </button>
              .
            </p>
          </div>
        )}
      </div>
    </Container>
  );
};

export default MyResume;
